import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Decoration1 from '../components/Decoration/Decoration-1';

import imageThirdSection1 from '../assets/images/photos/quest/image-1.png';
import imageThirdSection2 from '../assets/images/photos/quest/image-2.png';
import imageThirdSection3 from '../assets/images/photos/quest/image-3.png';
// import iconRetail from '../assets/images/vectors/quest/icon-retail.svg';
// import iconHelp from '../assets/images/vectors/quest/icon-help.svg';
// import iconPrice from '../assets/images/vectors/quest/icon-price.svg';
import iconPlane from '../assets/images/vectors/quest/plane.svg';
import iconShip from '../assets/images/vectors/quest/ship.svg';
import iconCar from '../assets/images/vectors/quest/car.svg';
import iconTours from '../assets/images/vectors/quest/tours.svg';
import iconHotel from '../assets/images/vectors/quest/hotel.svg';
import imagePrivilege1 from '../assets/images/photos/quest/priviliges-1.png';
import imagePrivilege2 from '../assets/images/photos/quest/priviliges-2.png';
import imagePrivilege3 from '../assets/images/photos/quest/priviliges-3.png';
import imagePrivilege4 from '../assets/images/photos/quest/priviliges-4.png';
import imagePrivilege5 from '../assets/images/photos/quest/priviliges-5.png';
import imagePrivilege6 from '../assets/images/photos/quest/priviliges-6.png';
import imagePrivilege7 from '../assets/images/photos/quest/priviliges-7.png';
import imagePrivilege8 from '../assets/images/photos/quest/priviliges-8.png';
import imagePrivilege9 from '../assets/images/photos/quest/priviliges-9.png';
import iconCheckIcon from '../assets/images/vectors/quest/check-icon.svg';

import '../assets/styles/pages/quest-page.scss';
import StartJourneyButton from '../components/StartJourneyButton';

const QuestPage = ({ data }) => {
  const prismicData = data.prismic.allQuest_pages.edges[0].node;
  return (
    <Layout>
      <SEO title="Quest" />
      <div className="quest-page">
        <FirstSection data={data} prismicData={prismicData} />
        <SecondSection data={data} prismicData={prismicData} />
        <ThirdSection prismicData={prismicData} />
        <FourthSection prismicData={prismicData} />
        <FifthSection prismicData={prismicData} />
        <SixthSection prismicData={prismicData} />
        <SeventhSection data={data} prismicData={prismicData} />
        <Eighthection prismicData={prismicData} />
      </div>
    </Layout>
  );
};

const FirstSection = ({ data: { imageFirstSection }, prismicData: { first_section_title } }) => (
  <section className="first-section">
    <section className="common-first-section">
      <div className="container">
        <div className="cfs__wrapper">
          <div className="cfs__l">
            <div>
              <h1 className="c-h1 cfs__l__title">{RichText.asText(first_section_title)}</h1>
              <Link to="/pricing/" className="c-btn">
                Start your journey
              </Link>
            </div>
          </div>
          <div className="cfs__r">
            <Decoration1 />
            <Img fluid={imageFirstSection.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  </section>
);

const SecondSection = ({
  data: { imageSecondSectionPhoto },
  prismicData: { second_section_title, second_section_content },
}) => (
  <section className="c-section-mt second-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5">
          <div className="second-section__left">
            <Img fluid={imageSecondSectionPhoto.childImageSharp.fluid} />
          </div>
        </div>
        <div className="col-12 col-md-6 offset-lg-1 second-section__right">
          <div className="c-h1">{RichText.asText(second_section_title)}</div>
          {RichText.render(second_section_content)}
        </div>
      </div>
    </div>
  </section>
);

const ThirdSection = ({ prismicData: { third_section_title, third_section_text } }) => (
  <section className="c-section-mt third-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="third-section__wrapper">
            <div className="third-section__left">
              <div className="c-h5--light">{RichText.asText(third_section_title)}</div>
              <div className="third-section__left__wrapper">
                <div className="c-h4 text-white">{RichText.asText(third_section_text)}</div>
              </div>
            </div>
            <div className="third-section__right">
              <div className="third-section__right__wrapper">
                <img src={imageThirdSection1} alt="" />
                <img src={imageThirdSection2} alt="" />
                <img src={imageThirdSection3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const FourthSection = ({
  prismicData: {
    fourth_section_title,
    fourth_section_subtitle,
    fourth_section_cards,
    fourth_section_content,
  },
}) => (
  <section className="fourth-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12 col-xl-10 mx-auto">
          <div className="c-h6--regular">{RichText.asText(fourth_section_title)}</div>
          <div>{RichText.asText(fourth_section_subtitle)}</div>
          <div className="fourth-section__cards">
            {fourth_section_cards.map((item, index) => (
              <div className="c-card" key={index}>
                <img src={item.icon.url} alt="" />
                <div className="c-h5--bold">{RichText.asText(item.title)}</div>
                <p>{RichText.asText(item.description)}</p>
                {item.note && <span className="c-caption-5">{RichText.asText(item.note)}</span>}
              </div>
            ))}
          </div>
          <p className="fourth-section__bottom">{RichText.asText(fourth_section_content)}</p>
        </div>
      </div>
    </div>
  </section>
);

const FifthSection = ({ prismicData: { fifth_section_title, fifth_section_subtitle } }) => (
  <section className="c-section-mt fifth-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="fifth-section__top">
            <div className="c-h1">{RichText.asText(fifth_section_title)}</div>
            <p>{RichText.asText(fifth_section_subtitle)}</p>
          </div>
          <div className="fifth-section__graph">
            <div className="fifth-section__graph__block">
              <div className="fifth-section__graph__block__content">
                <div className="fifth-section__graph__block__content__icon">
                  <img src={iconPlane} alt="" />
                </div>
                <p>Air travel</p>
              </div>
              <div className="fifth-section__graph__block__value">
                <span>$</span>
              </div>
            </div>
            <div className="fifth-section__graph__block">
              <div className="fifth-section__graph__block__content">
                <div className="fifth-section__graph__block__content__icon">
                  <img src={iconShip} alt="" />
                </div>
                <p>Cruises</p>
              </div>
              <div className="fifth-section__graph__block__value">
                <span>$$</span>
              </div>
            </div>
            <div className="fifth-section__graph__block">
              <div className="fifth-section__graph__block__content">
                <div className="fifth-section__graph__block__content__icon">
                  <img src={iconCar} alt="" />
                </div>
                <p>Transportation</p>
              </div>
              <div className="fifth-section__graph__block__value">
                <span>$$$</span>
              </div>
            </div>
            <div className="fifth-section__graph__block">
              <div className="fifth-section__graph__block__content">
                <div className="fifth-section__graph__block__content__icon">
                  <img src={iconTours} alt="" />
                </div>
                <p>Group tours</p>
              </div>
              <div className="fifth-section__graph__block__value">
                <span>$$$$</span>
              </div>
            </div>
            <div className="fifth-section__graph__block">
              <div className="fifth-section__graph__block__content">
                <div className="fifth-section__graph__block__content__icon">
                  <img src={iconHotel} alt="" />
                </div>
                <p>Lodging</p>
              </div>
              <div className="fifth-section__graph__block__value">
                <span>$$$$$</span>

                <div className="fifth-section__graph__block__value__hot">
                  <span>HOT!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SixthSection = ({ prismicData: { sixth_section_title, sixth_section_cards } }) => {
  const Card = ({ data: { title, subtitle, content, image } }) => (
    <div className="sixth-section__card">
      <div className="sixth-section__card__image">
        <img src={image.url} alt="" />
      </div>
      <div className="sixth-section__card__content">
        <div className="sixth-section__card__content__titles">
          <p>{RichText.asText(title)}</p>
          <span>{RichText.asText(subtitle)}</span>
        </div>
        <div className="sixth-section__card__content__description">{RichText.render(content)}</div>
      </div>
    </div>
  );

  return (
    <section className="c-section-mt sixth-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="c-h1">{RichText.asText(sixth_section_title)}</div>

            <div className="sixth-section__wrapper">
              {sixth_section_cards.map((item, index) => (
                <Card data={item} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SeventhSection = ({
  data: { imageSeventhSection },
  prismicData: {
    seventh_section_title,
    seventh_section_content,
    seventh_section_price,
    seventh_section_list,
  },
}) => (
  <section className="c-section-mt seventh-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6 seventh-section__image">
          {/* <img src={imageSeventhSection} className="w-100" alt="" /> */}
          <Img fluid={imageSeventhSection.childImageSharp.fluid} />
        </div>
        <div className="col-md-6 seventh-section__text">
          <div className="c-h1">{RichText.asText(seventh_section_title)}</div>
          {RichText.render(seventh_section_content)}
          <div className="seventh-section__text__list">
            {seventh_section_price.map((item, index) => (
              <div key={index}>
                <span>{RichText.asText(item.text)}</span>
                <span>{RichText.asText(item.amount)}</span>
              </div>
            ))}
          </div>
          <div className="seventh-section__text__features">
            {seventh_section_list.map((item, index) => (
              <div key={index}>
                <img src={iconCheckIcon} alt="" />
                <p>{RichText.asText(item.text)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Eighthection = ({ prismicData: { eigth_section_title } }) => (
  <section className="c-section-mt eighth-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="eighth-section__bg">
            <div className="eighth-section__wrapper">
              <div className="c-h2 text-white ">{RichText.asText(eigth_section_title)}</div>
              <StartJourneyButton text="Sign up now" styleName="c-btn--3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  query {
    prismic {
      allQuest_pages {
        edges {
          node {
            first_section_title
            second_section_title
            second_section_content
            third_section_title
            third_section_text
            fourth_section_title
            fourth_section_subtitle
            fourth_section_cards {
              icon
              title
              description
              note
            }
            fourth_section_content
            fifth_section_title
            fifth_section_subtitle
            sixth_section_title
            sixth_section_cards {
              title
              subtitle
              content
              image
            }
            seventh_section_title
            seventh_section_content
            seventh_section_price {
              text
              amount
            }
            seventh_section_list {
              text
            }
            eigth_section_title
          }
        }
      }
    }
    imageFirstSection: file(relativePath: { eq: "photos/quest/image-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 816, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageSecondSectionPhoto: file(relativePath: { eq: "photos/quest/image-second-section.png" }) {
      childImageSharp {
        fluid(maxWidth: 665, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageSeventhSection: file(relativePath: { eq: "photos/quest/image-seventh-section.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default QuestPage;
